import { Suspense } from 'react';

import Header from './components/header';
import Contact from './components/contact';
import Footer from './components/footer';
import HeroSection from './components/herosection';
import { useTranslation } from 'react-i18next';
import {Helmet} from "react-helmet";

export const DrupalMigratePage = () => {
    const { t } = useTranslation();

    return (
        <Suspense fallback="loading">
            <div className="App">
            <Helmet>
                <title>Developarg - Optimize your online presence: Migration to the latest version of Drupal</title>
                <meta name="description" content="At DevelopArg, we are committed to maximizing the potential of your digital platform. Our migration service to the latest version of Drupal offers numerous advantages, ensuring an updated and efficient online presence." />
                <meta name="keywords" content="Software development, Application development, Coding, Programming, Agile development, Software engineering, Development frameworks, Software architecture, Code optimization, Version control,
                Drupal CMS, Drupal development, Drupal modules, Drupal theming, Drupal migration, Drupal 9, Drupal community, Drupal security, Drupal best practices, Drupal customization,
                Drupal migration tools, Upgrade to Drupal 9, Drupal version migration, Content migration in Drupal, Migrate from Drupal 7 to 9, Data migration strategies, Module migration in Drupal, Drupal site migration, Planning Drupal migration, Smooth Drupal migration" />
            </Helmet>

                <Header />
                <HeroSection title={t('migration_site_title')} />


                <div className="container" id="main">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="blog-content">
                                <br />
                                <br />
                                <p className="lead">{t('migrate_page_intro')}</p>
                                <br />
                                <br />
                                <div className="row justify-content-center text-center mb-5 mt-5">
                                    <div className="col-md-5 aos-init aos-animate" data-aos="fade-down">
                                        <h2 className="section-heading">{t('migrate_page_porque')}</h2>
                                    </div>
                                </div>
                                <p className="lead color-green bold">{t('migrate_page_ventajas')}</p>
                                <p className="lead">
                                <ul>
                                    <li><b className="color-green">{t('rendimiento')}:</b> {t('rendimiento_desc')}</li>
                                    <li><b className="color-green">{t('seguridad')}: </b> {t('seguridad_desc')}</li>
                                    <li><b className="color-green">{t('nuevas_funcionalidades')}: </b> {t('nuevas_funcionalidades_desc')}</li>
                                    <li><b className="color-green">{t('compatibilidad')}: </b>{t('compatibilidad_desc')}</li>
                                </ul>
                                </p>
                                <br />
                                <br />
                            </div>
                        </div>
                    </div>
                    


                    <section className="section">
                    <div className="row justify-content-center text-center mb-2 mt-5">
                        <div className="col-md-5 aos-init aos-animate" data-aos="fade-down">
                            <h2 className="section-heading">{t('proceso_de_migracion')}</h2>
                        </div>
                    </div>

                    <div className="row">
              <div className="col-md-10 offset-md-1">
                <div className="step step-custom" data-aos="fade-up" data-aos-delay={10}>
                  
                  <h3><span className="number number-custom">01</span> <span className="color-blue">{t('step_one_title')}</span></h3>
                  <p>{t('step_one_desc')}</p>
                </div>
              </div>
              <div className="col-md-10 offset-md-1" data-aos="fade-up" data-aos-delay={20}>
                <div className="step step-custom">
                <h3><span className="number number-custom">02</span> <span className="color-blue">{t('step_two_title')}</span></h3>
                  <p>{t('step_two_desc')}</p>
                </div>
              </div>
              <div className="col-md-10 offset-md-1" data-aos="fade-up" data-aos-delay={30}>
                    <div className="step step-custom">
                    <h3><span className="number number-custom">03</span> <span className="color-blue">{t('step_three_title')}</span></h3>
                    <p>{t('step_three_desc')}</p>
                    </div>
                </div>
              </div>
                <div className="col-md-10 offset-md-1" data-aos="fade-up" data-aos-delay={40}>
                    <div className="step  step-custom">
                    <h3><span className="number number-custom">04</span> <span className="color-blue">{t('step_four_title')}</span></h3>
                    <p>{t('step_four_desc')}</p>
                    </div>
                </div>  
                <div className="col-md-10 offset-md-1" data-aos="fade-up" data-aos-delay={50}>
                    <div className="step step-custom">
                    <h3><span className="number number-custom">05</span> <span className="color-blue">{t('step_five_title')}</span></h3>
                    <p>{t('step_five_desc')}</p>
                    </div>
                </div>     
                <div className="col-md-10 offset-md-1" data-aos="fade-up" data-aos-delay={60}>
                    <div className="step step-custom">                   
                     <h3><span className="number number-custom">06</span> <span className="color-blue">{t('step_six_title')}</span></h3>
                    <p>{t('step_six_desc')}</p>
                    </div>
                </div>  
                <div className="col-md-10 offset-md-1" data-aos="fade-up" data-aos-delay={60}>
                    <div className="step step-custom">
                    <h3><span className="number number-custom">07</span> <span className="color-blue">{t('step_seven_title')}</span></h3>
                    <p>{t('step_seven_desc')}</p>
                    </div>
                </div>

                </section>
                </div>
                

                <Contact />

                <Footer />
            </div>
        </Suspense>
    )
}