import React from 'react';
import { useEffect, useState } from 'react';
import i18next from '../i18n'
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';

const Header = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const headerScrolled = () => {
      const header = document.querySelector('#header');
      if (header) {
        if (window.scrollY > 100) {
          header.classList.add('header-scrolled');
        } else {
          header.classList.remove('header-scrolled');
        }
      }
    };

    window.addEventListener('load', headerScrolled);
    window.addEventListener('scroll', headerScrolled);

    // Elimina los event listeners cuando el componente se desmonta
    return () => {
      window.removeEventListener('load', headerScrolled);
      window.removeEventListener('scroll', headerScrolled);
    };
  }, []);


  const changeLanguage = (language) => {
    i18next.changeLanguage(language)
  }


  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const handleMobileNavToggle = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  const handleDropdownClick = (e) => {
    if (isMobileNavOpen) {
      e.preventDefault();
      e.currentTarget.nextElementSibling.classList.toggle('dropdown-active');
    }
  };

  return (
    
    <header id="header" className="fixed-top d-flex align-items-center">
      
      <div className="container d-flex justify-content-between align-items-center">
        <div className="logo d-flex">

          <img src="img/logocolor.png"  className="img-fluid" />

          <div className='text-container'>
            <h1 className='color-white'>DevelopArg</h1>
            <p> Tech company</p>
          </div>
        </div>
        
        <nav id="navbar" className={isMobileNavOpen ? ' navbar navbar-mobile' : 'navbar'}>
            
          <ul>
            <li><a href={"/"}>Home</a></li>
            <li><HashLink to={"/#services"} onClick={() => setIsMobileNavOpen(false)}>{t('services')}</HashLink></li>
            <li><HashLink to={"/#howwework"} onClick={() => setIsMobileNavOpen(false)}>{t('how_we_work')}</HashLink></li>
            <li><HashLink to={"/#tools-platforms"} onClick={() => setIsMobileNavOpen(false)}>{t('tools_platforms')}</HashLink></li>
            <li><HashLink to={"/our-clients"} onClick={() => setIsMobileNavOpen(false)}>{t('our_clients')}</HashLink></li>
            <li><HashLink to={"/#contact"} onClick={() => setIsMobileNavOpen(false)}>{t('contactus')}</HashLink></li>

            <li class="dropdown"><a href="#" onClick={handleDropdownClick}><i class="bi bi-globe" style={{fontSize: '15px'}}></i><i class="bi bi-chevron-down" ></i></a>
              <ul>
                <li><a className="pointer" onClick={() => {
                  changeLanguage('es')
                  setIsMobileNavOpen(false)
                  }
                }>{t('spanish')}</a></li>
                <li><a className="pointer" onClick={() => {
                  changeLanguage('en')
                  setIsMobileNavOpen(false)
                  }
                }>{t('english')}</a></li>
              </ul>
            </li>
          </ul>
          <i className="bi bi-list mobile-nav-toggle" onClick={handleMobileNavToggle}/>
        </nav>
      </div>
    </header>
  );
}


export default Header;