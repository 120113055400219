import { useTranslation } from 'react-i18next';


const Tech = () => {
  const { t } = useTranslation();  
  return (
        <section className="section" id="tools-platforms">
        <div className="container">
             <div className="row justify-content-center text-center mb-5">
                <div className="col-md-5" data-aos="fade-up">
                  <h2 className="section-heading">{t('tools_platforms')}</h2>
                </div>
              </div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="/img/tech/drupal.png" className="tech-logo img-fluid" data-aos="fade-left" data-aos-delay={1} alt="" />
            </div>
            <div className="col-lg-4 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="/img/tech/nestjs.png" className="tech-logo img-fluid" data-aos="fade-left" data-aos-delay={180} alt="" />
            </div>
            <div className="col-lg-4 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="/img/tech/php-logo.svg" className="tech-logo img-fluid mw-30" data-aos="fade-left" data-aos-delay={220} alt="" />
            </div>
        
            <div className="col-lg-4 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="/img/tech/react-native.webp" className="tech-logo img-fluid" data-aos="fade-left" data-aos-delay={1} alt="" />
            </div>
            <div className="col-lg-4 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="/img/tech/react.png" className="tech-logo img-fluid" data-aos="fade-left" data-aos-delay={140} alt="" />
            </div>
           
            <div className="col-lg-4 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="/img/tech/angular.png" className="tech-logo img-fluid" data-aos="fade-left" data-aos-delay={180} alt="" />
            </div>
          </div>
        </div>
      </section>
    )
}

export default Tech;