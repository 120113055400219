import { useEffect } from 'react';
import './App.css';


import AOS from 'aos';
import 'aos/dist/aos.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {HomePage} from './HomePage';
import {DrupalMigratePage} from './DrupalMigratePage';
import {CasosExitoPage} from './CasosExitoPage';

function App() {  
  useEffect(() => {
    AOS.init(); 
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route 
          path="/" 
          element={<HomePage />}  
        />
        <Route 
          path="/drupal-migrate" 
          element={<DrupalMigratePage />}  
        />
        <Route 
          path="/our-clients" 
          element={<CasosExitoPage />}  
        />
      </Routes>
    </BrowserRouter>

  );
}

export default App;
