import { useTranslation } from 'react-i18next';

const Drupalmigrate = () => {
    const { t } = useTranslation();


    return (
          <section id="migrate-drupal" data-aos="fade-up" data-aos-delay={200} data-aos-offset={-500} >
            <div className="container">
                  <div className="row section cta-section drupal-section">
                  <div className="col-sm-7 offset-sm-1">
                    <h2>
                      {t('migrate_drupal')}
                      <span style={{color: "#7EEFC3"}}>{t('migrate_drupal_version')}</span>
                      {t('migrate_drupal_now')}
                    </h2>

                    <p>{t('migrate_drupal_desc')}</p>
                  </div>
                  <div className='col-sm-4 d-flex align-items-center justify-content-center'>
                    <a href={"/drupal-migrate"} className="btn d-inline-flex align-items-center">{t('migrate_drupal_cta')}</a>
                  </div>
              </div>           


               
            </div>
          </section>
      );
}

export default Drupalmigrate;