import { Suspense } from 'react';

import Header from './components/header';
import Hero from './components/hero';
import PreHeader from './components/pre-header';
import Clients from './components/clients';
import Contact from './components/contact';
import Footer from './components/footer';
import Counts from './components/counts';
import Tech from './components/tech';
import Whatwedo from './components/whatwedo';
import Drupalmigrate from './components/drupalmigrate';
import OurServices from './components/ourservices';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';

export const HomePage = () => {
    const { t } = useTranslation();

    return (
        <Suspense fallback="loading">

            <Helmet>
                <title>Developarg - Turning ideas into digital experiences</title>
                <meta name="description" content="We are a leading company in web solutions and mobile applications based in Buenos Aires, Argentina. Specializing in Drupal, we are ready to take your platform to the next level." />
                <meta name="keywords" content="Software development, Application development, Coding, Programming, Agile development, Software engineering, Development frameworks, Software architecture, Code optimization, Version control,
                Drupal CMS, Drupal development, Drupal modules, Drupal theming, Drupal migration, Drupal 9, Drupal community, Drupal security, Drupal best practices, Drupal customization,
                Drupal migration tools, Upgrade to Drupal 9, Drupal version migration, Content migration in Drupal, Migrate from Drupal 7 to 9, Data migration strategies, Module migration in Drupal, Drupal site migration, Planning Drupal migration, Smooth Drupal migration" />
            </Helmet>


            <div className="App">
                <PreHeader />
                <Header />
                <Hero />
                <Drupalmigrate />

                
                <main id="main">

                <OurServices/>
                <Whatwedo />
                <Tech />

                <Counts />
                <Clients />
                <Contact />
                </main>

                <Footer />
            </div>
        </Suspense>
    )
}