import React from "react";
import { useTranslation } from "react-i18next";

const PreHeader = () => {
  const { t } = useTranslation();

  return (
    <div class="row">
      <div class="navbar-top bg-grey fix">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="navbar-callus text-left sm-text-center">
                <ul class="list-inline spacing-element">
                  <li>
                    <a
                      href={`https://api.whatsapp.com/send?phone=542364469135&text=${t(
                        "whatsapp"
                      )}`}
                      style={{ color: "#2C2AAA" }}
                    >
                      <i class="bi bi-whatsapp"></i>
                      <span style={{ color: "#212528" }}></span>
                    </a>
                  </li>
                  <li>
                    <i class="fa fa-envelope-o"></i>
                    <span style={{ color: "#212528" }}>
                      {t("contact_us")}:{" "}
                    </span>
                    <a
                      href="mailto:contacto@developarg.com"
                      style={{ color: "#2C2AAA" }}
                    >
                      contacto@developarg.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreHeader;
