import { useTranslation } from 'react-i18next';


const Whatwedo = () => {

    const { t } = useTranslation();

    return (
        <section className="section" id="howwework">
          <div className="container">
            
            <div className="row">
              <div className="col">
                <h2 className="section-heading text-center mb-5" data-aos="fade-down" data-aos-delay={100}>{t('how_we_work')}</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="step" data-aos="fade-up" data-aos-delay={10}>
                  <span className="number">01</span>
                  <h3>{t('agile_methodology')}</h3>
                  <p>{t('agile_methodology_desc')}</p>
                </div>
              </div>
              <div className="col-md-4" data-aos="fade-up" data-aos-delay={20}>
                <div className="step">
                  <span className="number">02</span>
                  <h3>{t('version_control')}</h3>
                  <p>{t('version_control_desc')}</p>
                </div>
              </div>
              <div className="col-md-4" data-aos="fade-up" data-aos-delay={30}>
                    <div className="step">
                    <span className="number">03</span>
                    <h3>{t('focus_on_quality')}</h3>
                    <p>{t('focus_on_quality_desc')}</p>
                    </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4" data-aos="fade-up" data-aos-delay={40}>
                    <div className="step ">
                    <span className="number">04</span>
                    <h3>{t('communication_management')}</h3>
                    <p>{t('communication_management_desc')}</p>
                    </div>
                </div>  
                <div className="col-md-4" data-aos="fade-up" data-aos-delay={50}>
                    <div className="step">
                    <span className="number">05</span>
                    <h3>{t('documentation')}</h3>
                    <p>{t('documentation_desc')}</p>
                    </div>
                </div>     
                <div className="col-md-4" data-aos="fade-up" data-aos-delay={60}>
                    <div className="step">
                    <span className="number">06</span>
                    <h3>{t('focus_on_security')}</h3>
                    <p>{t('focus_on_security_desc')}</p>
                    </div>
                </div>  
                </div>
          </div>
        </section>
      );
}

export default Whatwedo;