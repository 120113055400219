import React, {useState} from "react";
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [formStatus, setFormStatus] = useState(false);

  let handleSubmit = async (e) => {
    e.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("Accept-Encoding", "application/json");
    
    var formdata = new FormData();
    formdata.append("name", name);
    formdata.append("email", email);
    formdata.append("message", message);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch("https://developarg.com/mail/index.php", requestOptions)
      .then(response => response.text())
      .then(
        result => {
          setFormStatus(true)
          setName("");
          setEmail("");
          setMessage("")

          setTimeout(() => {
            setFormStatus(false)
          }, 3000);
        }
      )
      .catch(error => console.log('error', error));
  }
  
  // Llamada a la función enviarFormulario con los datos del formulario
    return (
      <section className="section" id="contact">
        <div className="container">
          <div className="row mb-5 align-items-end">
            <div className="col-md-6" data-aos="fade-up">
              <h2 className="section-heading text-left" >{t('lets_talk')}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 ms-auto order-2" data-aos="fade-up">
              <ul className="list-unstyled">
                <li className="mb-3">
                  <strong className="d-block mb-1">{t('address')}</strong>
                  <span>Silvio Ruggieri 2700, CABA, Argentina</span>
                </li>
                <li className="mb-3">
                  <strong className="d-block mb-1">{t('email')}</strong>
                  <span><a href="mailto:contacto@developarg.com">contacto@developarg.com</a></span>
                </li>
              </ul>
              <p className="social">
                <a href="https://www.linkedin.com/company/92569863/admin/feed/posts/" target="_blank"><span className="bi bi-linkedin" /></a>
              </p>
            </div>
            <div className="col-md-6 mb-5 mb-md-0" data-aos="fade-up">
              <form onSubmit={handleSubmit} className="php-email-form">
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label htmlFor="name">{t('name')}</label>
                    <input 
                      type="text" 
                      name="name" 
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      id="name" required />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <label htmlFor="name">{t('email')}</label>
                    <input 
                      type="email" 
                      className="form-control" 
                      name="email" 
                      id="email" 
                      value={email}
                      required 
                      onChange={(e) => setEmail(e.target.value)}                    
                    />
                  </div>
                 
                  <div className="col-md-12 form-group mt-3">
                    <label htmlFor="name">{t('message')}</label>
                    <textarea 
                      className="form-control"
                      name="message" 
                      value={message}
                      required
                      rows={10}
                      onChange={(e) => setMessage(e.target.value)}/>
                  </div>

                  <div className="col-md-12 mb-3">
                    <div className={formStatus ? "sent-message d-block" : "sent-message"}>Your message has been sent. Thank you!</div>
                  </div>
            
                  <div className="col-md-6 form-group">
                    <button type="submit" className="btn btn-primary d-block w-100 mt-4">{t('send')}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      );
}

export default Contact;