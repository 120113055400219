import { useTranslation } from 'react-i18next';


const OurServices = () => {
    const { t } = useTranslation();

    return (
        <>
        

        <section id="services" className="section">
  <div className="container">
    <div className="row justify-content-center text-center mb-5">
      <div className="col-md-5 aos-init aos-animate" data-aos="fade-down">
        <h2 className="section-heading">{t('services')}</h2>
      </div>
    </div>
    <div className="row">
      <div
        className="col-md-4 aos-init aos-animate" 
        data-aos="fade-up" data-aos-delay={60} data-aos-offset={-500}
      >
        <div className="feature-1 text-center">
          <div className="wrap-icon icon-1">
            <i className="bi bi-pc-display-horizontal" />
          </div>
          <h3 className="mb-3">{t('web_development')}</h3>
          <p>
            { t('web_development_desc') }
          </p>
        </div>
      </div>
      <div
        className="col-md-4 aos-init aos-animate"
        data-aos="fade-up" data-aos-delay={60} data-aos-offset={-500}
      >
        <div className="feature-1 text-center">
          <div className="wrap-icon icon-1">
            <i className="bi bi-phone" />
          </div>
          <h3 className="mb-3">{t('mobile_development')}</h3>
          <p>
          {t('mobile_development_desc')}
          </p>
        </div>
      </div>
      <div
        className="col-md-4 aos-init aos-animate"
        data-aos="fade-up" data-aos-delay={60} data-aos-offset={-500}
      >
        <div className="feature-1 text-center">
          <div className="wrap-icon icon-1">
            <i className="bi bi-people" />
          </div>
          <h3 className="mb-3">{t('staffing')}</h3>
          <p>
          {t('staffing_desc')}
          </p>
        </div>
      </div>
    </div>
  </div>
</section>



      </>
    );
}

export default OurServices;