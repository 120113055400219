import { Suspense } from 'react';

import Header from './components/header';
import Hero from './components/hero';
import About from './components/about';
import Clients from './components/clients';
import Contact from './components/contact';
import Footer from './components/footer';
import Counts from './components/counts';
import Tech from './components/tech';
import Whatwedo from './components/whatwedo';
import Drupalmigrate from './components/drupalmigrate';
import HeroSection from './components/herosection';
import { useTranslation } from 'react-i18next';

export const CasosExitoPage = () => {
    const { t } = useTranslation();

    return (
        <Suspense fallback="loading">
            <div className="App">
                <Header />
                <HeroSection title={t('our_clients')} />


                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="blog-content" id="main">

                            <div className="row">
                                <div className="col-md-12">
                                    <p className="lead mt-5">



                                    {t('clients_text_one')} <br /><br />
                                    {t('clients_text_two')}
                                    

                                    <ul className="mt-3">
                                        <li><b><a className="color-green" href="https://www.yale.edu/" target="_blank">Yale University</a></b></li>
                                        <li><b><a className="color-green" href="https://www.un.org/es/iseek" target="_blank">United Nations</a></b>,</li>
                                        <li><b><a className="color-green" href="https://www.asu.edu/" target="_blank">Arizona State University</a></b></li>
                                        <li><b><a className="color-green" href="https://www.cookcountyil.gov/" target="_blank">Cook County Government</a></b></li>
                                    </ul>
                
                                    {t('clients_text_three')}               
                                    
                                    <ul className="mt-3">
                                        <li><b><a className="color-green" href="http://legalshield.com/" target="_blank">LegalShield</a></b></li>
                                        <li><b><a className="color-green" href="https://wwe.com" target="_blank">WWE</a></b></li>
                                        <li><b><a className="color-green" href="https://www.ustires.org/" target="_blank">USTIRES</a></b></li>
                                        <li><b><a className="color-green" href="https://bimbo.com.ar/" target="_blank">Bimbo</a></b></li>
                                    </ul>


                                    {t('clients_text_four')}
                                    </p>
                                </div>
                            </div>    

                            <hr className='mtb-8'></hr>

                            <div class="row">
                                <div className="d-sm-none mobile-note">
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                        <img src="/img/exito/cdc_desktop.png" className="img-fluid mw-60"></img>
                                        </div>
                                </div>

                                <div className="col-md-6">
                                    <a href="https://cdc.junin.gob.ar" target="_blank"><h3 className="color-green">{t('ciudad_conocimiento_title')}</h3></a>
                                    <p className="lead">
                                        {t('ciudad_conocimiento_desc')}
                                    </p>
                                </div>

                                <div className="col-md-6 d-none d-sm-block">
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                        <img src="/img/exito/cdc_desktop.png" className="img-fluid mw-60"></img>
                                        </div>
                                </div>
                            </div>
                            <hr className='mtb-8'></hr>

                            <div class="row">
                                 <div className="col-md-6">
                                    <div className="d-flex justify-content-center align-items-center h-100 mobile-note">
                                        <img src="/img/exito/vo.png" className="img-fluid mw-60"></img>
                                        </div>
                                    </div>
                                <div className="col-md-6">
                                    <a href="https://veterinariaonline.com.ar" target="_blank"><h3 className="color-green">{t('veterinaria_online_title')}</h3></a>

                                    <p className="lead">
                                        {t('veterinaria_online_desc')}
                                    </p>
                                </div>
                                
                            </div>
                            <hr className='mtb-8'></hr>

                            <div class="row">
                            <div className="d-sm-none mobile-note">
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                        <img src="/img/exito/voz.png" className="img-fluid mw-60"></img>
                                        </div>
                                </div>
                            <div className="col-md-6">
                                    <a href="https://lavozvedia.com/" target="_blank"><h3 className="color-green">{t('la_voz_title')}</h3></a>
                                    <p className="lead">
                                        {t('la_voz_desc')}
                                    </p>
                                </div>
                                 <div className="col-md-6 d-none d-sm-block">
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                        <img src="/img/exito/voz.png" className="img-fluid mw-60"></img>
                                        </div>
                                    </div>
                            </div>
                            <hr className='mtb-8'></hr>

                            <div class="row">
                            <div className="col-md-6 mobile-note">
                                <div className="d-flex justify-content-center align-items-center h-100">
                                    <img src="/img/exito/po_desktop.png" className="img-fluid mw-60"></img>
                                    </div>
                            </div>
                                <div className="col-md-6">
                                    <h3 className="color-green">{t('portal_obras_title')}</h3>
                                    <p className="lead">    
                                        {t('portal_obras_desc')}
                                    </p>
                                </div>
                               
                            </div>


                            <hr className='mtb-8'></hr>


                            </div>
                        </div>
                    </div>
                </div>
                

                <Contact />

                <Footer />
            </div>
        </Suspense>
    )
}